@charset "UTF-8";
/* ==========================================================================
  Colors
  ========================================================================== */
/* Primary Color
  ========================================================================== */
/* Secondary Color
  ========================================================================== */
/* Monochrome
  ========================================================================== */
/* Specific colors
  ========================================================================== */
/* ==========================================================================
  Typography
  ========================================================================== */
/* Settings
  ========================================================================== */
/* Families
  ========================================================================== */
/* Weights
  ========================================================================== */
/* Heading
  ========================================================================== */
/* ==========================================================================
  Grid / Responsive
  ========================================================================== */
/* Breakpoints
  ========================================================================== */
/* Spacing
  ========================================================================== */
/* ==========================================================================
  Transitions
  ========================================================================== */
/*
* Ceaser CSS Animation Tool - https://matthewlein.com/tools/ceaser
*/
/* ==========================================================================
  Scrollbar
  ========================================================================== */
/* ==========================================================================
  Activate features
========================================================================== */
/* ==========================================================================
	Breakpoints
	========================================================================== */
/* ==========================================================================
	Colors

	Only used to generate css properties in styleguide.scss
	========================================================================== */
/* ==========================================================================
	Container
	========================================================================== */
/* ==========================================================================
	Grid
	NB : Même si on n'utilise plus le grid system, je laisse ces mixins
	pour utilisation manuelle au besoin.

	Le margin négatif et la padding sont commenté, à réactiver au besoin.
========================================================================== */
/**
 * Returns opaque color
 * Ex.: opaque(#fff, rgba(0, 0, 0, .5)) => #808080
 */
.c-newsletter {
  --body-text-color: var(--primary-first);
  --body-heading-color: var(--primary-first);
  --form-mt: 20px;
  --form-btn-mt: 15px;
  padding: var(--spacing-responsive) 0;
  background: var(--secondary-first);
  max-width: 1920px;
  margin: 0 auto;
}
.c-newsletter__container {
  padding: 0 var(--half-container);
}
.c-newsletter__media {
  display: none;
}
.c-newsletter__form__content--title {
  text-align: center;
}
.c-newsletter__form__content--text {
  font-size: 1.3125rem;
  font-family: var(--font-secondary);
  line-height: 1.25;
  text-align: center;
}
.c-newsletter__form form {
  margin-top: var(--form-mt);
  text-align: center;
}
.c-newsletter__form form button {
  --primary: var(--primary-first);
  --secondary: var(--white);
  margin-top: var(--form-btn-mt);
  color: var(--secondary-first);
}
.c-newsletter__form form button:hover, .c-newsletter__form form button:active, .c-newsletter__form form button:focus {
  background: transparent;
}
@media (min-width: 768px) {
  .c-newsletter {
    --form-mt: 35px;
    --form-btn-mt: 0;
  }
  .c-newsletter__form__content {
    max-width: 514px;
    margin: 0 auto;
  }
  .c-newsletter__form__content--text {
    max-width: 448px;
    margin: 0 auto;
  }
  .c-newsletter__form form {
    display: flex;
    align-items: flex-end;
    -moz-column-gap: 6px;
    column-gap: 6px;
  }
  .c-newsletter__form form > div {
    flex: 1;
  }
  .c-newsletter__form form:has(> div > .c-formElement.has-error) {
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .c-newsletter {
    --form-ptb: 50px;
    --form-plr: 50px;
    padding: 0;
    background: none;
  }
  .c-newsletter__container {
    padding: 0;
  }
  .c-newsletter__media {
    display: block;
    flex: 1;
  }
  .c-newsletter__media .c-baseImage {
    height: 100%;
  }
  .c-newsletter__media picture img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .c-newsletter__row {
    display: flex;
  }
  .c-newsletter__form {
    display: flex;
    align-items: center;
    flex: 0 0 48%;
    padding: var(--form-ptb) var(--form-plr);
    background: var(--secondary-first);
  }
}
@media (min-width: 1450px) {
  .c-newsletter {
    --form-mt: 45px;
    --form-ptb: 85px;
  }
}
@media (min-width: 1650px) {
  .c-newsletter {
    --form-mt: 50px;
    --form-ptb: 105px;
  }
}